// TESTIMONIALS

$(function () {
	new Swiper('[data-slider="testimonial-slider"]', {
		slidesPerView: 1,
		spaceBetween: 20,
		observer: true,
		observeParents: true,
		speed: 800,
		navigation: {
			prevEl: '.testimonial__slider-nav-arrow.arrow-left',
			nextEl: '.testimonial__slider-nav-arrow.arrow-right'
		},
		breakpoints: {
			767: {
				slidesPerView: 2,
				spaceBetween: 20
			},
			1240: {
				slidesPerView: 2,
				spaceBetween: 32
			}
		}
	});
});
