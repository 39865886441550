// HEADER
function windowWidth() {
	return $(window).width();
}

$(function () {
	// Navigation
	$('.hamburger').on('click', function () {
		$('body').toggleClass('is-open-menu');

		// Change aria-expanded attribute
		$('.hamburger').attr('aria-expanded', function (i, attr) {
			return attr === 'false';
		});
	});

	// Open language menu
	$('.language-switch__button').on('click', function () {
		var parentElem = $(this).parents('.language-switch');
		parentElem.toggleClass('is-open');

		if (windowWidth() < 1023) {
			parentElem.find('.language-switch__dropdown').slideToggle('fast');
		}
	});

	// Add class on scroll
	$(window).scroll(function () {
		var scroll = $(window).scrollTop();

		if (scroll >= 20) {
			$('body').addClass('is-scrolled');
			if (windowWidth() > 1023) {
				$('.language-switch').removeClass('is-open');
			}
		} else {
			$('body').removeClass('is-scrolled');
		}
	});
});
